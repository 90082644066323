<template>
  <div class="RegisterOwnerMgr-container">
    <list :searchUrl="searchUrl" :exportUrl="exportUrl" exportPermission="export" :searchParams.sync="searchParams" :headers="headers" >
      <template #searchSlot>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" :startTime.sync="searchParams.startInTime" :endTime.sync="searchParams.endInTime" label="注册时间"/>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" :startTime.sync="searchParams.startApproveDate" :endTime.sync="searchParams.endApproveDate" label="审核操作时间"/>
        <v-input v-model="searchParams.userName" label="姓名"></v-input>
        <v-input v-model="searchParams.mobileNum" label="手机号"></v-input>
        <v-select2 v-model="searchParams.orgId" label="所属组织"  v-bind="orgIdConfig"></v-select2>
        <v-select2 v-model="searchParams.communityId" label="所属项目"  v-bind="communityIdConfig"></v-select2>
        <v-input v-model="searchParams.address" label="苑"></v-input>
        <v-input v-model="searchParams.buildNum" label="幢"></v-input>
        <v-input v-model="searchParams.unitNum" label="单元"></v-input>
        <v-input v-model="searchParams.roomNum" label="室"></v-input>
        <v-select v-model="searchParams.sex" label="性别" :options="sexOps"></v-select>
        <v-select v-model="searchParams.userType" label="身份" :options="userTypeOps"></v-select>
        <v-input v-model="searchParams.cardNum" label="证件号"></v-input>
        <v-select v-model="searchParams.realnameExamine" label="是否认证" :options="realnameExamineOps"></v-select>
        <v-select2 v-model="searchParams.regionId" label="所属公司"  v-bind="regionIdConfig"></v-select2>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" :startTime.sync="searchParams.startRealnameApplyTime" :endTime.sync="searchParams.endRealnameApplyTime" label="实名提交时间"/>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" :startTime.sync="searchParams.startRealnameApproveTime" :endTime.sync="searchParams.endRealnameApproveTime" label="实名通过时间"/>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" :startTime.sync="searchParams.startDeleteTime" :endTime.sync="searchParams.endDeleteTime" label="注销时间"/>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
    <el-drawer v-if="subPageType == 'drawer'" append-to-body :wrapperClosable="false" size="80%" destroy-on-close :visible.sync="showDrawer">
      <owner-mgr-form  :editQuery='editQuery'/>
    </el-drawer>
  </div>
</template>
<script>
import { getOwnerMgrList, exportUrl } from './api'
import * as commonData from '../ownerMgrForm/map'
import { communityParams, orgParams, regionParams } from '@/common/select2Params'
import ownerMgrForm from '../ownerMgrForm/OwnerMgrForm'
import moment from 'moment'
export default {
  name: 'RegisterOwnerMgr',
  components: {
    ownerMgrForm
  },
  data () {
    return {
      searchUrl: getOwnerMgrList,
      showDrawer:false,
      editQuery:{},
      exportUrl: exportUrl,
      maxDate: new Date(),
      searchParams: {
        startInTime: '',
        endInTime: '',
        startApproveDate: '',
        endApproveDate: '',
        userName: '',
        mobileNum: '',
        orgId: undefined,
        communityId: undefined,
        address: '',
        buildNum: '',
        unitNum: '',
        roomNum: '',
        sex: undefined,
        userType: undefined,
        cardNum: '',
        realnameExamine: undefined,
        regionId: undefined,
        startRealnameApplyTime: '',
        endRealnameApplyTime: '',
        startRealnameApproveTime: '',
        endRealnameApproveTime: '',
        startDeleteTime: '',
        endDeleteTime: ''
      },
      orgIdConfig: orgParams,
      communityIdConfig: communityParams,
      regionIdConfig: regionParams,
      sexOps: commonData.searchSexOps,
      userTypeOps: commonData.searchUserTypeOps,
      realnameExamineOps: commonData.realnameExamineOps,
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'alias',
          label: '称谓'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            let sex = commonData._sex[row.sex]
            return sex
          }
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'cardNum',
          label: '证件号'
        },
        {
          prop: 'intime',
          label: '注册时间'
        },
        {
          prop: 'deleteTime',
          label: '注销时间'
        },
        {
          prop: 'approveDateArr',
          label: '审核操作时间'
        },
        {
          prop: 'realnameApplyTime',
          label: '实名提交时间'
        },
        {
          prop: 'realnameExamine',
          label: '实名认证',
          formatter (row) {
            let realnameExamine = commonData._realnameExamine[row.realnameExamine]
            return realnameExamine
          }
        },
        {
          prop: 'realnameApproveTime',
          label: '实名通过时间'
        },
        {
          prop: 'realNameReviewer',
          label: '实名审核人'
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'address',
          label: '住址'
        },
        {
          prop: 'userTypeName',
          label: '身份'
        },
        {
          prop: 'approveUserName',
          label: '审核人'
        },
        {
          prop: 'adminStatus',
          label: '状态',
          formatter: row => commonData.adminStatusMap[row.adminStatus]
        }
      ]
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    let format = 'YYYY-MM-DD HH:mm'
    this.searchParams.startInTime = moment().subtract(7, 'days').format(format)
    this.searchParams.endInTime = moment().format(format)
  },
  methods: {
    edit (row) {
      if (this.subPageType == 'drawer' ){
         this.editQuery = {
          id: row.id,
          orgId: row.orgId,
          type: 1,
          communityId: row.communityId
        }
        this.showDrawer = true
        return
      }
      this.$router.push({
        name: 'newOwnerMgrForm',
        query: {
          id: row.id,
          orgId: row.orgId,
          type: 1,
          communityId: row.communityId
        }
      })
    }
  }
}
</script>
